.App {
  text-align: center;
}

.App-logo {
  height: 5rem;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.Links img{
  height: 2rem;
  border-radius: 2rem;
  box-shadow: #00000033 0px 0px 6px 3px;
}
.Links-container{
  display: flex;
  grid-gap: 24px;

}
/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
